import { Site } from "../types/Site";

const API_URL = "/api/air-emissions/sites";
console.log("API_URL", API_URL);

// Retrieve all sites
export const getAllSites = async (): Promise<Site[]> => {
  console.log("Fetching all sites from: ", API_URL);
  try {
    const response = await fetch(API_URL);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    console.log("API response:", data); // Log the response data
    return data;
  } catch (error) {
    console.error("Error fetching sites:", error);
    return [];
  }
};

// get a site by id
export const getSite = async (id: number): Promise<Site> => {
  //console.log(`Fetching site with id ${id} from:`, `${API_URL}/${id}`); // Log the full endpoint
  try {
    const response = await fetch(`${API_URL}/${id}`);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching site:", error);
    throw error;
  }
};
