import React, { useState } from "react";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Box } from "@mui/material";

const TimePeriodSelector = () => {

    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);

    return (

        <Box
            display="flex"
            justifyContent="center"  
            alignItems="center" 
            margin="auto"
            width="50%"
            flexDirection="column">
            <p style={{textAlign: 'center'}}>Filter By Time Period</p>
            <Box 
                display="flex" 
                alignItems = "center">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker label="Start Date" format="yyyy/MM/dd" maxDate={endDate || undefined} value={startDate} onChange={(newStartDate: Date | null) => setStartDate(newStartDate)}/>
                        <div data-testid="date-to" style={{padding: "2vw"}}>To</div>
                        <DatePicker label="End Date" format="yyyy/MM/dd" minDate={startDate || undefined} value={endDate} onChange={(newEndDate: Date | null) => setEndDate(newEndDate)}/>
                </LocalizationProvider>
            </Box>
        </Box>
    )


};

export default TimePeriodSelector;