import React, { useEffect, useState } from 'react';
import { Marker, Popup } from 'react-leaflet';
import { Icon } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import {getAllSampledStreams} from '../services/StreamService';
import { Stream } from '../types/Stream';
import {getWaterQualitySampleForStation} from "../services/WaterQualitySampleService";
import {getAllStreamParameters} from "../services/StreamParameterService";
import {WaterQualitySample} from "../types/WaterQualitySample";
// import {getAllStreamParameters} from "../services/StreamParameterService";
import {StreamParameter} from "../types/StreamParameter";

const StreamSourceMarkers: React.FC = () => {
    //const [qualityData, setQualityData] = useState<WaterQualitySample[]>([])
    const [streamData, setStreamData] = useState<Stream[]>([])
    const [streamSampleData, setStreamSampleData] = useState<WaterQualitySample[]>([])
    const [streamParameterData, setStreamParameterData] = useState<StreamParameter[]>([])
    //const [iconLocations, setIconLocation] = useState<number[][]>([[0, 0]])
    const [loadingStream, setLoadingStream] = useState(true);
    const [loadingSamples, setLoadingSamples] = useState(true);
    const [loadingStreamParameter, setLoadingStreamParameter] = useState<boolean>(true)
    //const [loadingQuality, setLoadingQuality] = useState(true);
    const [iconsAdded, setIconsAdded] = useState(false);
    const [markers, setMarkers] = useState([])
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {

        // Fetches all streams that have corresponding samples
        const fetchStream = async () => {
            if (!loadingStream) {
                return;
            }
            try {
                const streams = await getAllSampledStreams();
                // console.log('Fetched streams:', streams); // Log the fetched streams
                if (Array.isArray(streams)) {
                    if (streams.length > 0) {
                        setStreamData(streams);
                    }
                    else { return }
                } else {
                    setError('Failed to fetch streams');
                    console.log(error)
                }
            } catch (err) {
                console.error('Error fetching streams:', err);
                setError('Failed to fetch streams');
            } finally {
                setLoadingStream(false);
            }
        };

        fetchStream()

        // Fetches all stream parameter data (averages)
        const fetchStreamParameters = async () => {
            if (!loadingStreamParameter) {
                return;
            }
            try {
                const streamParameterData = await getAllStreamParameters();
                if (Array.isArray(streamParameterData)) {
                    if (streamParameterData.length > 0) {
                        setStreamParameterData(streamParameterData);
                        console.log("STREAM PARAMETER DATA FOUND:")
                        console.log(streamParameterData)
                    }
                    else { return }
                } else {
                    setError('Failed to fetch stream parameter data');
                    console.log(error)
                }
            } catch (err) {
                console.error('Error fetching stream parameter data:', err);
                setError('Failed to fetch stream parameter data');
            } finally {
                setLoadingStreamParameter(false);
            }
        };

        fetchStreamParameters()

    }, [])

    // Get one sample for each stream that is to be displayed
    const fetchSamples = async () => {
        if (!loadingSamples) {
            return;
        }

        await Promise.all(streamData.map(async (stream) => {
            try {
                const sample = await getWaterQualitySampleForStation(stream.station);
                if (sample) {
                    setStreamSampleData((currentStreamSampleData) => ([...currentStreamSampleData, sample]))
                } else {
                    setError('Failed to fetch a stream sample');
                    console.log(error)
                }
            } catch (err) {
                console.error('Error fetching stream samples:', err);
                setError('Failed to fetch stream samples');
            } finally {
                setLoadingSamples(false);
            }

        }))

    };

    useEffect(() => {

        if(!loadingStream) { fetchSamples() }

    }, [streamData])

    // map the streams
    const mapIcons = () => {
        if (iconsAdded) {
            return;
        }

        if (!loadingStream && !loadingSamples && streamData.length > 0 && streamSampleData.length >= streamData.length) {
            const marks = (streamData.map((stream) => {
                try {

                    // Fetch a sample taken from this stream
                    const sample = streamSampleData.find(
                        (sample) => sample.station === stream.station);

                    const sampleAvg = streamParameterData.find(
                        (param) => param.parameterCode === sample.parameter && param.unit === sample.unit);

                    if (sample && sampleAvg) {
                        return (
                            <Marker icon={new Icon({iconUrl: "/images/pin.png", iconSize: [32, 32], iconAnchor: [16, 32]})}
                                    position={[stream.latitude, stream.longitude]}
                                    key={stream.id}>
                                <Popup>
                                    <b>Stream:</b> {stream.name}
                                    <br/>
                                    <b>Description:</b> {stream.locationDescription}
                                    <br/>
                                    <b>Station ID:</b> {stream.station}
                                    <br/>

                                    <br/>
                                    One Sample from this stream:
                                    <br/>
                                    <br/>

                                    <b>Sample Parameter:</b> {sample.parameterDescription}
                                    <br/>
                                    <b>Sample Reading:</b> {sample.result}
                                    <br/>
                                    <b>Average Reading:</b> {sampleAvg.average}
                                    <br/>
                                    <b>Unit:</b> {sample.unit}
                                    <br/>
                                </Popup>
                            </Marker>
                        )
                    }

                } catch (error) {
                    console.log(error)
                    return;
                }
            }))
            setMarkers(marks)
            setIconsAdded(true)
        }
    }

    useEffect(() => {

        mapIcons()

    }, [streamSampleData]);

    return (<>
        {markers}
    </>);

}

export default StreamSourceMarkers;
