import { Contaminants } from "../types/Contaminants";

const API_URL = "/api/air-emissions/contaminants";
console.log("API_URL", API_URL);

// Retrieve all Contaminants
export const getAllContaminants = async (): Promise<Contaminants[]> => {
  console.log("Fetching all Contaminants from: ", API_URL);
  try {
    const response = await fetch(API_URL);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    console.log("API response:", data); // Log the response data
    return data;
  } catch (error) {
    console.error("Error fetching Contaminants:", error);
    return [];
  }
};

// get a Contaminant by id
export const getContaminatById = async (id: number): Promise<Contaminants> => {
  console.log(`Fetching Contaminants with id ${id} from:`, `${API_URL}/id/${id}`); // Log the full endpoint
  try {
    const response = await fetch(`${API_URL}/id/${id}`);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching Contaminants:", error);
    throw error;
  }
};


// get Contaminants by name (can return multiple)
export const getContaminantByName = async (name: string): Promise<Contaminants[]> => {
    //console.log(`Fetching Contaminants with name ${name} from:`, `${API_URL}/name/${name}`); // Log the full endpoint
    try {
      const response = await fetch(`${API_URL}/name/${name}`);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching Contaminants:", error);
      throw error;
    }
  };