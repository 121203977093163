import { Exceedance } from "../types/Exceedance";

const API_URL = "/api/air-emissions/exceedances";
console.log("API_URL", API_URL);

// Retrieve all exceedances
export const getAllExceedances = async (): Promise<Exceedance[]> => {
  console.log("Fetching all exceedances from: ", API_URL);
  try {
    const response = await fetch(API_URL);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    console.log("API response:", data); // Log the response data
    return data;
  } catch (error) {
    console.error("Error fetching exceedances:", error);
    return [];
  }
};

// get a exceedance by id
export const getExceedance = async (id: number): Promise<Exceedance> => {
  console.log(`Fetching exceedance with id ${id} from:`, `${API_URL}/${id}`); // Log the full endpoint
  try {
    const response = await fetch(`${API_URL}/${id}`);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching exceedance:", error);
    throw error;
  }
};
