import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, LineElement, PointElement, ChartData } from "chart.js";
import { getAllStreamParameters } from "../services/StreamParameterService";

ChartJS.register(CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Tooltip, Legend);

interface IStreamParameter {
	parameter: string;
	average: number;
	unit: string;
}

const StreamParameterBarChart: React.FC = () => {
	const [streamParameters, setStreamParameters] = useState<IStreamParameter[]>([]);

	const dataFetchedRef = React.useRef(false);

	useEffect(() => {
		const fetchData = async () => {
			if (dataFetchedRef.current) {
				return; // not fetching data if already fetched
			}
			dataFetchedRef.current = true;
			try {
				const data = await getAllStreamParameters();
				const filteredData = data.filter(param => param.unit === "MICROGRAM PER LITER");
				setStreamParameters(filteredData);
			} catch (error) {
				console.error("Error fetching stream parameters data", error);
			}
		};

		fetchData();
	}, []);

	const chartData: ChartData<'bar'> = {
		labels: streamParameters.map(param => param.parameter),
		datasets: [
			{
				label: "Average Value",
				data: streamParameters.map(param => param.average),
				borderWidth: 1,
				backgroundColor: "#03a9f4",
				barThickness: 15,
				order: 2,
				type: 'bar',
			},
		],
	};

	const chartOptions = {
		responsive: true,
		plugins: {
			title: {
				display: true,
				text: "Stream Parameter Averages",
				font: {
					size: 18,
				},
			},
		},
		scales: {
			x: {
				title: {
					display: true,
					text: "Stream Parameter",
					font: {
						size: 14,
					},
				},
				grid: {
					display: false,
				},
				ticks: {
					autoSkip: true,
					maxRotation: 90,
					minRotation: 45,
				},
			},
			y: {
				title: {
					display: true,
					text: "Average Value (MICROGRAM PER LITER)",
					font: {
						size: 14,
					},
				},
			},
		},
		barPercentage: 0.7,
		categoryPercentage: 0.9,
	};

	if (streamParameters.length === 0) {
		return <div>Loading...</div>;
	}

	return (
		<div style={{ width: "80%", margin: "auto", marginTop: "100px", marginBottom: "100px" }}>
			<h1
				style={{
					textAlign: "center",
					marginTop: "7vh",
					fontSize: "50px",
					fontFamily: "Lato, sans-serif",
					fontWeight: "600",
					fontStyle: "semibold",
					color: "grey",
				}}
			>
				Stream Parameter Average Values
			</h1>
			<Bar data={chartData} options={chartOptions} />
			<p style={{ textAlign: "center", marginTop: "20px", fontSize: "16px", color: "#555" }}>
				The above chart shows the average values of stream parameters.
			</p>
		</div>
	);
};

export default StreamParameterBarChart;
