import React from "react";
import ContaminantBarChart from "../components/ContaminantBarChart";
import StreamParameterBarChart from "../components/StreamParameterBarChart";

const Graphs: React.FC = () => {
	return (
		<div style={{ padding: "20px", color: "black" }}>
			<h1
				style={{
					textAlign: "center",
					marginTop: "7vh",
					fontSize: "68px",
					fontFamily: "Lato, sans-serif",
					fontWeight: "600",
					fontStyle: "semibold",
					color: "grey"
				}}
			>
				Graphs
			</h1>
			<ContaminantBarChart />
			<StreamParameterBarChart />
		</div>
	);
};

export default Graphs;
