import { SiteLocation } from "../types/SiteLocation";

const API_URL = "/api/air-emissions/site-locations";
console.log("API_URL", API_URL);

// Retrieve all Site locations
export const getAllSiteLocations = async (): Promise<SiteLocation[]> => {
    //console.log("Fetching all siteLocations from: ", API_URL);
    try {
        const response = await fetch(API_URL);
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        //console.log("API response:", data); // Log the response data
        return data;
    } catch (error) {
        console.error("Error fetching Site Locations:", error);
        return [];
    }
};

// retrieve site locations by address
export const getSiteLocationByAddress = async (address: string): Promise<SiteLocation> => {
    //console.log(`Fetching all siteLocations with address ${address} from: `, `${API_URL}/${address}`);
    try {
        const response = await fetch(`${API_URL}/${address}`);
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        //console.log("API response:", data); // Log the response data
        return data;
    } catch (error) {
        console.error(`Error fetching Site Location: ${address}`, error);
        throw error
    }
};