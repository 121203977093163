import React from 'react';

interface ChevronDownProps {
    size?: number,
    color?: string,
    className?: string,
}

const ChevronDown: React.FC<ChevronDownProps> = ({ size = 24, color = 'black', className = '' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill={color}
      width={size}
      height={size}
      className={className}
    >
        {/* credit to chatGPT for the svg path */}
        <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6z" />
    </svg>
  );
};

export default ChevronDown;
