import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, LineElement, PointElement, ChartData } from "chart.js";
import { getAllContaminants } from '../services/ContaminantService';


ChartJS.register(CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Tooltip, Legend);

interface IContaminant {
	contaminant: string;
	averageMinimum: number;
	averageMaximum: number;
	contaminantLimit: number;
	unit: string;
}

const ContaminantBarChart: React.FC = () => {
	const [contaminants, setContaminants] = useState<IContaminant[]>([]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const data = await getAllContaminants();
				//console.log("Fetched data:", data);
				const filteredData = data.filter(c => c.unit === "µg/m≥");
				setContaminants(filteredData);
			} catch (error) {
				console.error("Error fetching contaminants data", error);
			}
		};

		fetchData();
	}, []);


	const chartData: ChartData<'bar'> = {
		labels: contaminants.map(c => c.contaminant),
		datasets: [
			{
				label: "Average Minimum",
				data: contaminants.map(c => c.averageMinimum),
				borderWidth: 1,
				backgroundColor: "#03a9f4",
				barThickness: 15,
				order: 2,
				type: 'bar',
			},
			{
				label: "Average Maximum",
				data: contaminants.map(c => c.averageMaximum),
				borderWidth: 1,
				backgroundColor: "#ffa500",
				barThickness: 15,
				order: 2,
				type: 'bar',
			},
			{
				label: "Regulatory Limit",
				data: contaminants.map(c => c.contaminantLimit),
				type: 'bar',
				barThickness: 15,
				//fill: false,
				backgroundColor: "#D30000",
				order: 1
			},
		],
	};

	const chartOptions = {
		responsive: true,
		plugins: {
			title: {
				display: true,
				text: "Average Contaminant Levels vs Regulatory Limit",
				font: {
					size: 18,
				},
			},
		},
		scales: {
			x: {
				title: {
					display: true,
					text: "Contaminant",
					font: {
						size: 14,
					},
				},
				grid: {
					display: false,
				},
				ticks: {
					autoSkip: true,
					maxRotation: 90,
					minRotation: 45,
				},
			},
			y: {
				title: {
					display: true,
					text: "Concentration (µg/m≥)",
					font: {
						size: 14,
					},
				},
			},
		},
		barPercentage: 0.7,
		categoryPercentage: 0.9,
	};

	if (contaminants.length === 0) {
		return <div>Loading...</div>;
	}

	return (
		<div style={{ width: "80%", margin: "auto", marginTop: "100px", marginBottom: "100px" }}>
			<h1
				style={{
					textAlign: "center",
					marginTop: "7vh",
					fontSize: "50px",
					fontFamily: "Lato, sans-serif",
					fontWeight: "600",
					fontStyle: "semibold",
					color: "grey"
				}}
			>
				Contaminant Data Analysis: Averages and Regulatory Compliance
			</h1>
			<Bar data={chartData} options={chartOptions} />
			<p style={{ textAlign: "center", marginTop: "20px", fontSize: "16px", color: "#555" }}>
				The above chart shows the average levels of key air contaminants across different facilities and their corresponding regulatory limits.
			</p>
		</div>
	);
};

export default ContaminantBarChart;