import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Graphs from "./pages/Graphs";

const App: React.FC = () => {
  return (
    <Routes>
      <Route path="/">
        <Route index element={<Home />} />
        <Route path="graphs" element={<Graphs />} />
      </Route>
    </Routes>
  );
};

export default App;
