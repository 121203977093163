import React, { useEffect, useState } from 'react';
import { Marker, Popup } from 'react-leaflet';
import { Icon } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { getAllExceedances } from '../services/ExceedanceService';
import { Exceedance } from '../types/Exceedance';
import { getSite } from '../services/SiteService';
import { getSiteLocationByAddress } from '../services/SiteLocationService';
import { getAllContaminants } from '../services/ContaminantService';
import { Contaminants } from '../types/Contaminants';

const AirQualitySourceMarkers: React.FC = () => {
    const [exceedanceData, setExceedanceData] = useState<Exceedance[]>([])
    const [loadingExceedances, setLoadingExceedances] = useState(true)
    const [contaminantsData, setContaminantsData] = useState<Contaminants[]>([])
    const [loadingContaminants, setLoadingContaminants] = useState(true)
    const [iconsAdded, setIconsAdded] = useState(false)
    const [error, setError] = useState<string | null>(null);
    const [markers, setMarkers] = useState([])

    useEffect(() => {

        // Fetch exceedance data
        const fetchExceedance = async () => {
            if (!loadingExceedances) {
                return;
            }
            try {
                const data = await getAllExceedances();
                // console.log('Fetched contaminantData:', contaminantData); // Log the fetched contaminantData
                if (Array.isArray(data)) {
                    if (data.length > 0) {
                        setExceedanceData(data);
                    } else { return }
                } else {
                    setError('Failed to fetch contaminantData');
                    console.log(error)
                }
            } catch (err) {
                console.error('Error fetching contaminantData:', err);
                setError('Failed to fetch contaminantData');
            } finally {
                setLoadingExceedances(false);
            }
        };
        fetchExceedance();

        const fetchContaminants = async () => {
            if (!loadingContaminants) {
                return;
            }
            try {
                const data = await getAllContaminants();
                // console.log('Fetched contaminantData:', contaminantData); // Log the fetched contaminantData
                if (Array.isArray(data)) {
                    if (data.length > 0) {
                        setContaminantsData(data);
                    } else { return }
                } else {
                    setError('Failed to fetch contaminantData');
                    console.log(error)
                }
            } catch (err) {
                console.error('Error fetching contaminantData:', err);
                setError('Failed to fetch contaminantData');
            } finally {
                setLoadingContaminants(false);
            }
        };
        fetchContaminants();

        const mapIcons = async () => {
            if (iconsAdded) {
                return;
            }
            if (!loadingExceedances && !loadingContaminants) {
                const marks = await Promise.all(exceedanceData.map(async (ex) => {
                    try {
                        const site = await getSite(ex.siteId);
                        const coords = await getSiteLocationByAddress(site.address);
                        const contaminant = contaminantsData.filter((contam) => contam.contaminant === ex.contaminant && ex.contaminantUnit === contam.unit)[0]
                        // console.log(contaminant, ex.contaminant)
                        // console.log("site", site, "location", coords, "exceedance:", ex);
                        return (
                            <Marker icon={new Icon({ iconUrl: "/images/pin_orange.png", iconSize: [32, 32], iconAnchor: [16, 32] })}
                                position={[coords.latitude, coords.longitude]}
                                key={ex.id}>
                                <Popup>
                                    <div>
                                        <b>Site:</b> {ex.siteId} at: {site.address}
                                        <br />
                                        <b>Owner:</b> {site.owner}
                                        <hr />
                                        <b>Exceedance information:</b>
                                        <br />
                                        <b>Type:</b> {ex.contaminant}
                                        <br />
                                        <b>Minimum: </b>{ex.quantityMinimum} {ex.contaminantUnit}
                                        <br />
                                        <b>avg: </b> {contaminant?.averageMinimum} {contaminant?.unit}
                                        <br />
                                        <b>Maximum: </b> {ex.quantityMaximum} {ex.contaminantUnit}
                                        <br />
                                        <b>avg: </b> {contaminant?.averageMaximum} {contaminant?.unit}
                                        <br />
                                        <b>Start Date: </b> {new Date(ex.startDate).toLocaleDateString()}
                                        <br />
                                        <b>End Date: </b> {new Date(ex.endDate).toLocaleDateString()}
                                    </div>
                                </Popup>
                            </Marker>)
                    } catch (error) {
                        console.log(error)
                        // no match for the address.
                        // or no match for the contaminant
                        return;
                    }
                }))
                // console.log(marks)
                setMarkers(marks)
                setIconsAdded(true)
            }
        };
        mapIcons()
    })
    return (<>
        {markers}
    </>);
}

export default AirQualitySourceMarkers