import { Stream } from "../types/Stream";

const API_URL = "/api/stream-quality-monitoring/streams";
console.log("API_URL", API_URL);

// Retrieve all Streams
export const getAllStreams = async (): Promise<Stream[]> => {
  console.log("Fetching all Streams from: ", API_URL);
  try {
    const response = await fetch(API_URL);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    console.log("API response:", data); // Log the response data
    return data;
  } catch (error) {
    console.error("Error fetching Streams:", error);
    return [];
  }
};

// Retrieve all Streams with samples
export const getAllSampledStreams = async (): Promise<Stream[]> => {
  console.log("Fetching all sampled Streams from: ", `${API_URL}/sampled`);
  try {
    const response = await fetch(API_URL + "/sampled");
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    console.log("API response:", data); // Log the response data
    return data;
  } catch (error) {
    console.error("Error fetching Streams:", error);
    return [];
  }
};

// get a Stream by id
export const getStream = async (id: number): Promise<Stream> => {
  console.log(`Fetching Stream with id ${id} from:`, `${API_URL}/${id}`); // Log the full endpoint
  try {
    const response = await fetch(`${API_URL}/${id}`);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching Stream:", error);
    throw error;
  }
};
