import React, { useEffect, memo } from 'react';
import { MapContainer, TileLayer, useMap } from 'react-leaflet';
// import { Icon } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import StreamSourceMarkers from './StreamSourceMarkers';
import AirQualitySourceMarkers from './AirQualitySourceMarkers';
import { LatLng } from 'leaflet';



//export default function Map({addresses}: MapProps) {
export default memo(function WaterWizeMap() {

    // const addresses = ["Reynolds Walk, Guelph, ON", "290 Bremner Blvd, Toronto, ON"]
    const popups = [
        [43.642690395331556, -79.38711044619014], // CN Tower
        [43.53304060550301, -80.22630914602537], // University of Guelph
        [43.55707335106224, -80.25321115387602], // Guelph General Hospital
        [43.4951274119409, -80.1800247290717], // Springfield Golf Club
        [43.521620016609624, -80.23350595506183], // Edinburgh Metro
        [46.20943461392341, -79.76429688389267], // Lake Nipissing
    ]

    const init_position = popups[1];

    const SearchField = () => {

        const provider = new OpenStreetMapProvider();

        const searchControl = GeoSearchControl({
            provider: provider,
            showMarker: false,
            showPopup: false,
        });

        const map = useMap();

        useEffect(() => {
            map.addControl(searchControl);
            return () => { map.removeControl(searchControl); };
        }, []);

        return null;

    };

    return (
        <section className='map' data-testid='leaflet-map'>
            <MapContainer center={new LatLng(init_position[0], init_position[1])} zoom={13} scrollWheelZoom={false}
                style={{ "width": "100%", "height": "100%" }}>
                <SearchField />
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />

                <StreamSourceMarkers />
                <AirQualitySourceMarkers />

            </MapContainer>
        </section>
    );
});
