import { WaterQualitySample } from "../types/WaterQualitySample";

const API_URL = "/api/stream-quality/samples";
console.log("API_URL", API_URL);

// Retrieve all WaterQualitySamples
export const getAllWaterQualitySamples = async (): Promise<
  WaterQualitySample[]
> => {
  console.log("Fetching all WaterQualitySamples from: ", API_URL);
  try {
    const response = await fetch(API_URL);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    console.log("API response:", data); // Log the response data
    return data;
  } catch (error) {
    console.error("Error fetching WaterQualitySamples:", error);
    return [];
  }
};

// get a WaterQualitySample by id
export const getWaterQualitySample = async (
  id: number
): Promise<WaterQualitySample> => {
  console.log(
    `Fetching WaterQualitySample with id ${id} from:`,
    `${API_URL}/${id}`
  ); // Log the full endpoint
  try {
    const response = await fetch(`${API_URL}/${id}`);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching WaterQualitySample:", error);
    throw error;
  }
};

// get a WaterQualitySample for a station
export const getWaterQualitySampleForStation = async (
    station: string
): Promise<WaterQualitySample> => {
  const endpoint = `${API_URL}/sample`
  try {
    const response = await fetch(`${endpoint}/${station}`);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching WaterQualitySample:", error);
    throw error;
  }

};
