import { StreamParameter } from "../types/StreamParameter";

const API_URL = "/api/stream-quality/streamParameters";
console.log("API_URL", API_URL);

// Retrieve all Stream Parameter records
export const getAllStreamParameters = async (): Promise<StreamParameter[]> => {
    console.log("Fetching all StreamParameters from: ", API_URL);
    try {
        const response = await fetch(API_URL);
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        console.log("API response:", data); // Log the response data
        return data;
    } catch (error) {
        console.error("Error fetching StreamParameters:", error);
        return [];
    }
};

// get a StreamParameter record by id
export const getStreamParameterById = async (id: number): Promise<StreamParameter> => {
    console.log(`Fetching StreamParameter with id ${id} from:`, `${API_URL}/id/${id}`); // Log the full endpoint
    try {
        const response = await fetch(`${API_URL}/id/${id}`);
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error fetching StreamParameter:", error);
        throw error;
    }
};


// get StreamParameter by code
export const getStreamParameterByCode = async (code: string): Promise<StreamParameter[]> => {
    console.log(`Fetching StreamParameter with code ${code} from:`, `${API_URL}/code/${code}`); // Log the full endpoint
    try {
        const response = await fetch(`${API_URL}/name/${code}`);
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error fetching StreamParameter:", error);
        throw error;
    }
};
